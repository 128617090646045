// External imports
import React, { useEffect } from 'react';
import {
  Collapse,
  Drawer,
  ListItemText,
  MenuList,
  MenuItem,
  Typography,
  Modal,
  withStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import update from 'immutability-helper';
import get from 'lodash-es/get';
// Internal imports
import {
  SETTINGS_SHORTCUTS,
  HIERARCHY_TYPE,
  LOGO_TYPE,
  BILLING_SCHEMA,
  BUSINESS_SETUP_PAGES,
  INDIVIDUAL_ENTITY_TYPE,
  LICENSE_ACCESS_ROLES,
  EXTENDED_SETUP_PAGES,
  REQUIRED_SETUP_PAGES,
  SB_REQUIRED_SETUP_PAGES,
  COMMUNICATIONS_SETUP_PAGES,
  LATEST_TAXPASS_SEASON,
} from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers from '~/app/webHelpers.js';
import checkmarkImage from '~/images/icons/icons8-checkmark-48.png';
import OfficeSetup from '#/NewAccount/SingleOffice/OfficeSetup.jsx';
import GenBillingSetup from '#/NewAccount/SingleOffice/GenBillingSetup.jsx';
import BusinessBillingSetup from '#/NewAccount/SingleOffice/BusinessBillingSetup.jsx';
import EFilingSetup from '#/NewAccount/SingleOffice/EFilingSetup.jsx';
import PrintingSetup from '#/NewAccount/SingleOffice/PrintingSetup.jsx';
import EROSetup from '#/NewAccount/SingleOffice/EROSetup.jsx';
import PreparerSetup from '#/NewAccount/SingleOffice/PreparerSetup.jsx';
import CheckSetup from '#/NewAccount/SingleOffice/CheckSetup.jsx';
import LoginSetup from '#/Settings/Setup/Logins/LoginSetup.jsx';
import AuditSetup from '#/NewAccount/SingleOffice/Audit.jsx';
import DefaultSetup from '#/NewAccount/SingleOffice/Defaults.jsx';
import TextSetup from '#/NewAccount/SingleOffice/Text.jsx';
import ClientLetterSetup from '#/Settings/Setup/ClientLetters/ClientLetterSetup.jsx';
import BusClientLetterSetup from '#/Settings/Setup/ClientLetters/BusClientLetterSetup.jsx';
import CobrandingSetup from '~/app/Pages/Setup/AccountSetup/CobrandingSetup/CobrandingSetup.jsx';
import AncillaryProducts from '#/Settings/Setup/AncillaryProducts/AncillaryProducts.jsx';
import PaymentSetup from '~/app/Pages/Setup/PaymentSetup/PaymentSetup.jsx';
import MobileSetup from '~/app/Pages/Setup/MobileSetup/MobileSetup.jsx';
import LicencingSetup from '~/app/Pages/Setup/LicencingSetup/LicencingSetup.jsx';
import ManageLicencing from '~/app/Pages/Setup/ManageLicencing/ManageLicencing.jsx';
import RestrictedFields from '~/app/Pages/Setup/RestrictedFields/RestrictedFields.jsx';
import CopyOfficeModal from '~/app/Pages/Setup/Modals/CopyOfficeModal.jsx';
import Notifications from '~/app/Components/Common/Notifications/Notifications.jsx';
import { isDemoAccount, canAccessCorp } from '~/app/Utility/general';
// Redux imports
import { useSetState } from '~/app/Utility/customHooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
import { actions as officeSettingsActions } from '~/app/redux/modules/officeSettings.js';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
// Styling imports
import { styles, themeStyles } from '~/app/Pages/Setup/css/setup.js';
import '~/app/Pages/Setup/AccountSetup/css/accountSetup.css';

const compStyles = theme => themeStyles(theme);

const AccountSetup = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const {
    isCopyOfficeSettingModalOpen,
    settingsShortcut,
    currentView,
    ddh,
    drilldownHistory,
    loginID,
    officeSetupProgressFlags,
    canCobrand,
    isLoadingCobrandStatus,
    activeAccessLevels,
    hasAccessToAddCobranding,
    hasAccessToPurchaseCobranding,
    officeProfile,
    topMostParentRole,
  } = useSelector(state => ({
    isCopyOfficeSettingModalOpen: state.loginSetup.isCopyOfficeSettingModalOpen,
    settingsShortcut: state.setupPageState.settingsShortcut,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    ddh: state.drilldown.drilldownHistory,
    drilldownHistory: state.drilldown.drilldownHistory,
    loginID: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1].loginID,
    officeSetupProgressFlags: state.setupPageState.officeSetupProgressFlags,
    canCobrand: state.cobrand.canCobrand,
    isLoadingCobrandStatus: state.cobrand.isLoadingCobrandStatus,
    activeAccessLevels: loginSelector.getActiveAccessLevels(state),
    hasAccessToAddCobranding: state.cobrand.hasAccessToAddCobranding,
    hasAccessToPurchaseCobranding: state.cobrand.hasAccessToPurchaseCobranding,
    officeProfile: state.officeProfile,
    topMostParentRole: state.app.topMostParentRole,
  }));

  const sidebarTabMapIdx = {};
  let initialLoad = false;

  const isEfinPreparer = [HIERARCHY_TYPE.PREPARER, HIERARCHY_TYPE.EFIN].includes(currentView?.role);
  const payload = WebHelpers.getJWTPayload();

  const demoAccountFlag = isDemoAccount(payload);
  const getSideBarList = () => {
    let efinPreparerList = isEfinPreparer
      ? [
          {
            header: 'Required for Filing',
            pages: [
              {
                name: 'Office',
                id: 'office',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <OfficeSetup
                    loginID={loginID}
                    officeSetupProgressFlags={officeSetupProgressFlags}
                    onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                  />
                ),
              },
              {
                name: 'ERO',
                id: 'ero',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <EROSetup
                    loginID={loginID}
                    updateOfficeSetupFlag={(msg, type) =>
                      dispatch(setupPageActions.updateOfficeSetupFlag(msg, type))
                    }
                    officeSetupProgressFlags={officeSetupProgressFlags}
                    onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                    addFrontendNotification={evt =>
                      dispatch(notificationActions.addFrontendNotification(evt))
                    }
                  />
                ),
              },
              {
                name: 'Preparer',
                id: 'preparer',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <PreparerSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    officeSetupProgressFlags={officeSetupProgressFlags}
                    onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                  />
                ),
              },
              {
                name: 'Billing',
                id: 'billing',
                accessLevel: 'view_billing',
                isEnabled: true,
                component: (
                  <GenBillingSetup
                    loginID={loginID}
                    updateOfficeSetupFlag={(msg, type) =>
                      dispatch(setupPageActions.updateOfficeSetupFlag(msg, type))
                    }
                    officeSetupProgressFlags={officeSetupProgressFlags}
                    onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                  />
                ),
              },
              {
                name: 'Licensing',
                id: 'licensing',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: <LicencingSetup />,
              },
            ],
          },

          {
            header: 'Extended Setup',
            pages: [
              {
                name: 'EFiling',
                id: 'efiling',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <EFilingSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                    officeProfile={officeProfile}
                    currentView={currentView}
                  />
                ),
              },
              {
                name: 'Printing',
                id: 'printing',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <PrintingSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                    currentView={currentView}
                    officeProfile={officeProfile}
                  />
                ),
              },
              {
                name: 'Checks',
                id: 'checks',
                accessLevel: 'check_activation',
                isEnabled: true,
                component: (
                  <CheckSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                  />
                ),
              },
              {
                name: 'Logins',
                id: 'logins',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <LoginSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                  />
                ),
              },
              {
                name: 'Defaults',
                id: 'defaults',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <DefaultSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                  />
                ),
              },
              {
                name: 'Audit',
                id: 'audit',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <AuditSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                  />
                ),
              },
              {
                name: 'Client Letters',
                id: 'clientLetters',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <ClientLetterSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    entitycode={INDIVIDUAL_ENTITY_TYPE.ENTITYCODE}
                  />
                ),
              },
              {
                name: 'PayJunction',
                id: 'payjunction',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <PaymentSetup
                    ddh={ddh}
                    loadSetupComponent={component => loadSetupComponent(component)}
                  />
                ),
              },
              {
                name: 'Mobile Site',
                id: 'taxpassSite',
                accessLevel: 'view_office_setup',
                isEnabled: payload?.season >= LATEST_TAXPASS_SEASON, // When Taxyear 2021 is removed, this check for LATEST_TAXPASS_SEASON can be removed
                component: (
                  <MobileSetup
                    officeProfile={officeProfile}
                    loadSetupComponent={component => loadSetupComponent(component)}
                  />
                ),
              },
              {
                name: 'Restricted Fields',
                id: 'restrictedFields',
                accessLevel: 'create/edit_restricted_fields',
                isEnabled: true,
                component: (
                  <RestrictedFields
                    loadSetupComponent={component => loadSetupComponent(component)}
                  />
                ),
              },
            ],
          },
          {
            header: 'Communications',
            pages: [
              {
                name: 'Text',
                id: 'text',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <TextSetup
                    loginID={loginID}
                    loadSetupComponent={component => loadSetupComponent(component)}
                  />
                ),
              },
            ],
          },
        ]
      : []; // Load nothing to save some mem

    if (canAccessCorp(payload, officeProfile, currentView)) {
      efinPreparerList = [
        ...efinPreparerList.slice(0, 1),
        {
          header: 'Business',
          pages: [
            {
              name: 'Bus. Client Letters',
              id: 'businessClientLetters',
              accessLevel: 'view_office_setup',
              isEnabled: true,
              component: (
                <BusClientLetterSetup
                  loadSetupComponent={component => loadSetupComponent(component)}
                  loginID={loginID}
                />
              ),
            },
            {
              name: 'Business Billing',
              id: 'businessBilling',
              accessLevel: 'view_billing',
              isEnabled: true,
              component: (
                <BusinessBillingSetup
                  loadSetupComponent={component => loadSetupComponent(component)}
                  loginID={loginID}
                  updateOfficeSetupFlag={(msg, type) =>
                    dispatch(setupPageActions.updateOfficeSetupFlag(msg, type))
                  }
                  officeSetupProgressFlags={officeSetupProgressFlags}
                  onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                />
              ),
            },
          ],
        },
        ...efinPreparerList.slice(1),
      ];
    }
    const ssbList = !isEfinPreparer
      ? [
          {
            header: 'Setup',
            pages: [
              {
                name: 'Logins',
                id: 'logins',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <LoginSetup
                    loadSetupComponent={component => loadSetupComponent(component)}
                    loginID={loginID}
                    canCobrand={canCobrand}
                  />
                ),
              },
              {
                name: 'Ancillary Products',
                id: 'ancillaryProducts',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <AncillaryProducts
                    loginID={loginID}
                    loadSetupComponent={component => loadSetupComponent(component)}
                    canCobrand={canCobrand}
                    currentView={currentView}
                  />
                ),
              },
              {
                name: 'Cobranding',
                id: 'cobranding',
                accessLevel: 'view_office_setup',
                isEnabled: true,
                component: (
                  <CobrandingSetup
                    logoType={LOGO_TYPE.ACCOUNT}
                    loginID={loginID}
                    hierarchyType={currentView?.role}
                    setLogo={logo => dispatch(cobrandActions.setLogo(logo))}
                    loadSetupComponent={component => loadSetupComponent(component)}
                    canCobrand={canCobrand}
                  />
                ),
              },
              {
                name: 'Manage Licensing',
                id: 'manageLicensing',
                isEnabled: true,
                component: (
                  <ManageLicencing
                    loadSetupComponent={component => loadSetupComponent(component)}
                    canCobrand={canCobrand}
                    topMostParentRole={topMostParentRole}
                    isLoading={props.isLoading}
                  />
                ),
              },
            ],
          },
        ]
      : []; // Load nothing to save some mem
    let list = [];
    if (!isEfinPreparer) {
      if (!LICENSE_ACCESS_ROLES.includes(payload.roles[0].access_friendly_role_name)) {
        ssbList[0].pages.pop();
      }
      list = ssbList;
    } else {
      list = efinPreparerList;
    }
    // Filter out the list based on access levels and keep a mapping of the removed indicies
    const filteredList = list.map(section => {
      const newSection = Object.assign({}, section);
      let refIdx = 0;
      newSection.pages = section.pages.filter((pagetab, index) => {
        if (
          !(hasAccessToAddCobranding || hasAccessToPurchaseCobranding) &&
          pagetab.name === 'Cobranding' &&
          !canCobrand
        ) {
          return false;
        }

        if (Object.hasOwn(pagetab, 'accessLevel')) {
          if (Object.hasOwn(activeAccessLevels, pagetab.accessLevel)) {
            sidebarTabMapIdx[pagetab.name] = {
              oldIdx: index,
              newIdx: refIdx,
            };
            refIdx++;
            return pagetab;
          }
        } else {
          sidebarTabMapIdx[pagetab.name] = {
            oldIdx: index,
            newIdx: refIdx,
          };
          refIdx++;
          return pagetab;
        }
        return false;
      });
      return newSection;
    });
    return filteredList;
  };

  const getHeaders = () => {
    if (isEfinPreparer && demoAccountFlag) {
      return ['Required for Filing', 'Business', 'Extended Setup'];
    }
    return isEfinPreparer
      ? ['Required for Filing', 'Business', 'Extended Setup', 'Communications']
      : ['Setup'];
  };

  const [state, setState] = useSetState({
    sidebarList: getSideBarList(),
    selectedSidebarItem: 0,
    expandedHeaders: getHeaders(),
    openComponent: null,
    officeSetup: {},
    eroSetup: {},
    billingSetup: {},
    isEfinPreparer: [HIERARCHY_TYPE.PREPARER, HIERARCHY_TYPE.EFIN].includes(currentView?.role),
  });

  useEffect(() => {
    const isEfinPreparer = [HIERARCHY_TYPE.PREPARER, HIERARCHY_TYPE.EFIN].includes(
      currentView?.role,
    );

    settingsShortCuts();

    if (isEfinPreparer) {
      dispatch(setupPageActions.fetchOfficeSetupProgressFlags(currentView?.loginID));
    }

    dispatch(cobrandActions.fetchCobrandingPermission());
    // Update state
    setState({ isEfinPreparer });

    return () => {
      // Clear the sidebarTabMap when we unmount.
      dispatch(notificationActions.removeFrontEndNotification());
      dispatch(loginSetupActions.resetLoginsList());
    };
  }, [currentView?.role]);

  const settingsShortCuts = () => {
    if (settingsShortcut) {
      switch (settingsShortcut) {
        case SETTINGS_SHORTCUTS.PREPARER:
          getPreparerInfo();
          break;
        case SETTINGS_SHORTCUTS.LOGINS:
          handleSidebarRowClick(state.sidebarList[getFirstSetupIdx()].pages[0], 0);
          break;
      }
    } else {
      // const settings = loginSettings;

      // As default always open the "Office" tab

      // Since it's possible some of the components in Office Setup may be hidden, we need to  get the first entry in sidebarList[0].pages
      // TODO --> It is possible in the future all the 'Required For Filing' tabs will be controlled by access levels. getSideBarList should handle this so we
      // can always rely on pulling the first entry in sidebarList
      const index = 0;
      const row = state.sidebarList[getFirstSetupIdx()].pages[index];
      if (row) {
        handleSidebarRowClick(row, index);
      }
    }
  };

  const getFirstSetupIdx = () => {
    let retSetupIdx = 0;

    state.sidebarList.some((sectionTab, setupIdx) => {
      if (sectionTab.pages.length > 0) {
        retSetupIdx = setupIdx;
        return true;
      }
      return false;
    });
    return retSetupIdx; // There should be at least one entry in our sidebarList.
  };

  const getBillingGeneralOfficeInfo = sameAsOffice => {
    setState({
      selectedSidebarItem: 'Billing3',
    });

    const idx = sidebarTabMapIdx.Billing?.newIdx;

    XlinkAPI.getBillingOfficeInfo(0, loginID) // Pull the default billing scheme.
      .then(res => {
        const newSidebarList = update(state.sidebarList, {
          0: {
            pages: {
              [idx]: {
                component: {
                  $set: (
                    <GenBillingSetup
                      billingInfo={res.data}
                      loadSetupComponent={component => loadSetupComponent(component)}
                      loginID={loginID}
                      officeSetupProgressFlags={officeSetupProgressFlags}
                      onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                    />
                  ),
                },
              },
            },
          },
        });
        setState({
          sidebarList: newSidebarList,
          billingSetup: res.data,
          openComponent: (
            <GenBillingSetup
              billingInfo={res.data}
              loadSetupComponent={component => loadSetupComponent(component)}
              loginID={loginID}
              officeSetupProgressFlags={officeSetupProgressFlags}
              onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
            />
          ),
        });
        props.isLoading(false);
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get Billing Office Info', err);
      });
  };

  const getBusinessBillingInfo = () => {
    setState({
      selectedSidebarItem: 'Business Billing1',
    });
    const idx = sidebarTabMapIdx['Business Billing']?.newIdx;

    XlinkAPI.getBillingOfficeInfo(0, loginID, BILLING_SCHEMA.BUSINESS) // Pull the default billing scheme.
      .then(res => {
        const newSidebarList = update(state.sidebarList, {
          1: {
            pages: {
              [idx]: {
                component: {
                  $set: (
                    <BusinessBillingSetup
                      billingInfo={res.data}
                      loadSetupComponent={component => loadSetupComponent(component)}
                      loginID={loginID}
                      officeSetupProgressFlags={officeSetupProgressFlags}
                      onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                    />
                  ),
                },
              },
            },
          },
        });

        setState({
          sidebarList: newSidebarList,
          billingSetup: res.data,
          openComponent: (
            <BusinessBillingSetup
              billingInfo={res.data}
              loadSetupComponent={component => loadSetupComponent(component)}
              loginID={loginID}
              officeSetupProgressFlags={officeSetupProgressFlags}
              onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
            />
          ),
        });
        props.isLoading(false);
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get Business Billing Info', err);
      });
  };

  const getEROInfo = sameAsOffice => {
    setState({
      selectedSidebarItem: 'ERO1',
    });
    const idx = sidebarTabMapIdx.ERO?.newIdx;

    XlinkAPI.getEROSetup(sameAsOffice, loginID)
      .then(res => {
        const newSidebarList = update(state.sidebarList, {
          0: {
            pages: {
              [idx]: {
                component: {
                  $set: (
                    <EROSetup
                      eroSetup={res.data}
                      loadSetupComponent={component => loadSetupComponent(component)}
                      loginID={loginID}
                      updateOfficeSetupFlag={(msg, type) =>
                        dispatch(setupPageActions.updateOfficeSetupFlag(msg, type))
                      }
                      officeSetupProgressFlags={officeSetupProgressFlags}
                      onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                      addFrontendNotification={evt =>
                        dispatch(notificationActions.addFrontendNotification(evt))
                      }
                    />
                  ),
                },
              },
            },
          },
        });
        setState({
          sidebarList: newSidebarList,
          eroSetup: res.data,
          openComponent: (
            <EROSetup
              eroSetup={res.data}
              loadSetupComponent={component => loadSetupComponent(component)}
              loginID={loginID}
              updateOfficeSetupFlag={(msg, type) =>
                dispatch(setupPageActions.updateOfficeSetupFlag(msg, type))
              }
              officeSetupProgressFlags={officeSetupProgressFlags}
              onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
              addFrontendNotification={evt =>
                dispatch(notificationActions.addFrontendNotification(evt))
              }
            />
          ),
        });
        props.isLoading(false);
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get ERO Setup', err);
      });
  };

  const getOfficeInfo = () => {
    setState({
      selectedSidebarItem: 'Office0',
    });
    let activeEfinID = 0;
    const payload = WebHelpers.getJWTPayload();
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.preparer_info.active_efin_id;
    }
    const idx = sidebarTabMapIdx?.Office?.newIdx;
    XlinkAPI.getOfficeSetup(loginID, activeEfinID)
      .then(res => {
        dispatch(officeSettingsActions.setCurrentOfficeAddress(res?.data?.officeInfo));
        const newSidebarList = update(state.sidebarList, {
          0: {
            pages: {
              [idx]: {
                component: {
                  $set: (
                    <OfficeSetup
                      officeSetup={res.data}
                      loadSetupComponent={component => loadSetupComponent(component)}
                      officeSetupProgressFlags={officeSetupProgressFlags}
                      onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                      loginID={loginID}
                      efinID={activeEfinID}
                    />
                  ),
                },
              },
            },
          },
        });
        setState({
          sidebarList: newSidebarList,
          officeSetup: res.data,
          openComponent: (
            <OfficeSetup
              officeSetup={res.data}
              loadSetupComponent={component => loadSetupComponent(component)}
              officeSetupProgressFlags={officeSetupProgressFlags}
              onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
              loginID={loginID}
            />
          ),
        });
        props.isLoading(false);
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get Office Setup', err);
        props.isLoading(false);
      });
  };

  // Get Account Licencing Setup
  const getAccountLicenseSetup = () => {
    // Get the new index
    const idx = sidebarTabMapIdx['Manage Licensing']?.newIdx;

    setState({
      selectedSidebarItem: 'Manage Licensing' + idx,
    });
    // getAccountLicenseInfoByLoginID
    // Data returned in res.data needs to be passed to LicensingSetup component
    XlinkAPI.getAccountLicenseInfoByLoginID(payload.season).then(res => {
      const newSidebarList = update(state.sidebarList, {
        0: {
          pages: {
            [idx]: {
              component: {
                $set: (
                  <ManageLicencing
                    licencingSetup1040={res.data.licenseInfo1040}
                    licencingSetupBusiness={res.data.licenseInfoBusiness}
                    drillDownHistory={drilldownHistory}
                    loadSetupComponent={component => loadSetupComponent(component)}
                    canCobrand={canCobrand}
                    isLoading={props.isLoading}
                    topMostParentRole={topMostParentRole}
                  />
                ),
              },
            },
          },
        },
      });

      setState({
        sidebarList: newSidebarList,
        openComponent: (
          <ManageLicencing
            licencingSetup1040={res.data.licenseInfo1040}
            licencingSetupBusiness={res.data.licenseInfoBusiness}
            drillDownHistory={drilldownHistory}
            loadSetupComponent={component => loadSetupComponent(component)}
            canCobrand={canCobrand}
            isLoading={props.isLoading}
            topMostParentRole={topMostParentRole}
          />
        ),
      });
    });
  };

  // Get Licencing Info
  const getLicencingInfo = () => {
    // Get the new index
    const idx = sidebarTabMapIdx?.Licensing?.newIdx;
    setState({
      selectedSidebarItem: 'Licensing' + idx,
    });

    // TODO: an API call here and on response create the newSidebarListBelow
    XlinkAPI.getOfficeLicenseInfoByLoginID(loginID, officeProfile.is_feeder_office).then(res => {
      const newSidebarList = update(state.sidebarList, {
        0: {
          pages: {
            [idx]: {
              component: {
                $set: (
                  <LicencingSetup
                    licencingSetup={res.data}
                    loadSetupComponent={component => loadSetupComponent(component)}
                    currentView={currentView}
                  />
                ),
              },
            },
          },
        },
      });

      setState({
        sidebarList: newSidebarList,
        openComponent: (
          <LicencingSetup
            licencingSetup={res.data}
            loadSetupComponent={component => loadSetupComponent(component)}
            currentView={currentView}
          />
        ),
      });
      props.isLoading(false);
    });
  };

  // Get Preparer Info
  const getPreparerInfo = () => {
    setState({
      selectedSidebarItem: 'Preparer2',
    });
    // Get the new index
    const idx = sidebarTabMapIdx?.Preparer?.newIdx;
    XlinkAPI.getPreparerSetup()
      .then(res => {
        const newSidebarList = update(state.sidebarList, {
          0: {
            pages: {
              [idx]: {
                component: {
                  $set: (
                    <PreparerSetup
                      loadSetupComponent={component => loadSetupComponent(component)}
                      officeSetupProgressFlags={officeSetupProgressFlags}
                      onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
                    />
                  ),
                },
              },
            },
          },
        });
        setState({
          sidebarList: newSidebarList,
          officeSetup: res.data,
          openComponent: (
            <PreparerSetup
              loadSetupComponent={component => loadSetupComponent(component)}
              officeSetupProgressFlags={officeSetupProgressFlags}
              onSetupComplete={() => dispatch(drilldownActions.onSetupComplete())}
            />
          ),
        });
        props.isLoading(false);
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get Preparer Setup', err);
      });
  };

  const handleHeaderClick = header => {
    const headerIndex = state.expandedHeaders.indexOf(header);
    const newexpandedHeaders = [...state.expandedHeaders];

    if (headerIndex === -1) {
      newexpandedHeaders.push(header);
    } else {
      newexpandedHeaders.splice(headerIndex, 1);
    }

    setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  const handleSidebarItemHighlight = (row, index) => {
    return state.selectedSidebarItem === row.name + index;
  };

  // handleSideBarRowClick
  /*
    row -> Contains the component as well as the name of one of the side bar tabs.
    index -> Index of the row being clicked.
  */
  const handleSidebarRowClick = (row, index) => {
    index = sidebarTabMapIdx[row?.name]?.newIdx;

    if (!initialLoad) {
      initialLoad = true;
    }

    if (!props.isLoadingFlag) {
      props.isLoading(true);
    }

    if (row.name !== 'Logins' && index !== 0) {
      setState({
        selectedSidebarItem: row.name + index,
      });
    }

    dispatch(setupPageActions.onSelectSidebar(row.name));
    switch (row.name) {
      case 'Office':
        getOfficeInfo();
        break;
      case 'ERO':
        getEROInfo(false);
        break;
      case 'Preparer':
        getPreparerInfo();
        break;
      case 'Billing':
        getBillingGeneralOfficeInfo(false);
        break;
      case 'Business Billing':
        getBusinessBillingInfo();
        break;
      case 'Licensing':
        getLicencingInfo();
        break;
      case 'Manage Licensing':
        getAccountLicenseSetup();
        break;
      default:
        setState({
          selectedSidebarItem: row.name + index,
          openComponent: row.component,
        });
        // The components are loaded ahead of time already.  However,
        // in prod, when a user clicks through the nav quickly, it
        // sometimes causes the app to crash.  This time delay, in
        // showing the spinning loader will prevent user from clicking
        // quickly.
        setTimeout(() => {
          props.isLoading(false);
        }, 1000);
    }
  };

  // Loads the specified component - see constants.js
  const loadSetupComponent = component => {
    const canBusiness = canAccessCorp(payload, officeProfile, currentView);

    // If the business tab is available, we need to update the indexing
    const SB_REQUIRED_SETUP_PAGES_INDEX = 0;
    const BUSINESS_SETUP_PAGES_INDEX = canBusiness ? 1 : -1; // inactive
    const EXTENDED_SETUP_PAGES_INDEX = canBusiness ? 2 : 1;
    const COMMUNICATIONS_SETUP_PAGES_INDEX = canBusiness ? 3 : 2;

    // This is really ugly but until we have a better way to switch use two switch statements
    // One for the high level setup SB/SSB/MO and one for efin preparer setup
    const idx = sidebarTabMapIdx[component]?.newIdx || 0;

    if (isEfinPreparer === true) {
      switch (component) {
        case REQUIRED_SETUP_PAGES.OFFICE.NAME:
          getOfficeInfo();
          break;
        case REQUIRED_SETUP_PAGES.ERO.NAME:
          getEROInfo(false);
          break;
        case REQUIRED_SETUP_PAGES.PREPARER.NAME:
          getPreparerInfo();
          break;
        case REQUIRED_SETUP_PAGES.BILLING.NAME:
          getBillingGeneralOfficeInfo(false);
          break;
        case REQUIRED_SETUP_PAGES.LICENCING.NAME:
          getLicencingInfo();
          break;
        case BUSINESS_SETUP_PAGES.CLIENT_LETTERS.NAME:
        case BUSINESS_SETUP_PAGES.BILLING.NAME:
          handleSidebarRowClick(state.sidebarList[BUSINESS_SETUP_PAGES_INDEX].pages[idx]);
          break;
        case EXTENDED_SETUP_PAGES.EFILING.NAME:
        case EXTENDED_SETUP_PAGES.PRINTING.NAME:
        case EXTENDED_SETUP_PAGES.CHECKS.NAME:
        case EXTENDED_SETUP_PAGES.LOGINS.NAME:
        case EXTENDED_SETUP_PAGES.DEFAULTS.NAME:
        case EXTENDED_SETUP_PAGES.AUDIT.NAME:
        case EXTENDED_SETUP_PAGES.PAYMENTS.NAME:
        case EXTENDED_SETUP_PAGES.MOBILE.NAME:
        case EXTENDED_SETUP_PAGES.RESTRICTED_FIELDS.NAME:
        case EXTENDED_SETUP_PAGES.CLIENT_LETTERS.NAME:
          handleSidebarRowClick(state.sidebarList[EXTENDED_SETUP_PAGES_INDEX].pages[idx]);
          break;
        case COMMUNICATIONS_SETUP_PAGES.TEXT.NAME:
          handleSidebarRowClick(state.sidebarList[COMMUNICATIONS_SETUP_PAGES_INDEX].pages[idx]);
          break;
        case SB_REQUIRED_SETUP_PAGES.SETUP.ANCILLARY.NAME:
          handleSidebarRowClick(
            state.sidebarList[SB_REQUIRED_SETUP_PAGES_INDEX].pages[
              SB_REQUIRED_SETUP_PAGES.SETUP.ANCILLARY.INDEX
            ],
            SB_REQUIRED_SETUP_PAGES.SETUP.ANCILLARY.INDEX,
          );
          break;
        default:
          console.log('loadSetupComponent() ERROR: Setup Page Not Found for Efin/Preparer');
          break;
      }
    } else {
      const SB_SETUP_PAGES = SB_REQUIRED_SETUP_PAGES.SETUP;
      if (
        component === SB_SETUP_PAGES.LOGINS.NAME ||
        SB_SETUP_PAGES.ANCILLARY.NAME ||
        SB_SETUP_PAGES.PREPARER.NAME ||
        SB_SETUP_PAGES.COBRANDING.NAME
      ) {
        handleSidebarRowClick(state.sidebarList[0].pages[idx], idx);
      } else {
        console.log('loadSetupComponent() ERROR: Setup Page Not Found for SB/SSB/MO');
      }
    }
  };

  const sidebarRows = pages => {
    if (demoAccountFlag) {
      pages = pages.filter(
        n =>
          n.name !== 'Manage Licensing' &&
          n.name !== 'PayJunction' &&
          n.name !== 'Mobile Site' &&
          n.name !== 'Text',
      );
    }

    const rows = pages
      .filter(
        v =>
          v.isEnabled &&
          (hasAccessToAddCobranding ||
            hasAccessToPurchaseCobranding ||
            (v.name === 'Cobranding' && canCobrand && isLoadingCobrandStatus === false) ||
            v.name !== 'Cobranding'),
      )
      .map((row, index) => {
        return renderSideBarRow(row, index);
      });

    return rows;
  };

  const determineRequiredSetupStatusIcon = row => {
    if (row.id && officeSetupProgressFlags && Object.hasOwn(officeSetupProgressFlags, row.id)) {
      if (
        officeSetupProgressFlags[row.id] ||
        (row.id.includes('licensing') &&
          !officeSetupProgressFlags[row.id] &&
          officeProfile?.has1040License)
      ) {
        // Status complete.
        return <img src={checkmarkImage} className="sidebar-checkmark-img" />;
      } else {
        // Status to do.
        return <span className="setup-sidebar-dot-icon" />;
      }
    }
    return <></>;
  };

  const renderSideBarRow = (row, index) => {
    return (
      <span key={index}>
        <MenuItem
          classes={classes.menuItemRoot}
          button
          key={row.name}
          id={`miClickableRow${row.name}`}
          selected={handleSidebarItemHighlight(row, index)}
          onClick={() => handleSidebarRowClick(row, index)}
        >
          <ListItemText
            disableTypography
            inset
            primary={
              <Typography type="body2" style={styles.formItemStyle}>
                {determineRequiredSetupStatusIcon(row)}
                {row.name}
              </Typography>
            }
            style={row.id ? { paddingLeft: '5px' } : { paddingLeft: '20px' }}
          />
        </MenuItem>
      </span>
    );
  };

  const generateSidebar = () => {
    let sidebar = state.sidebarList;
    // in case of CLODMO account do not include Communications header
    if (demoAccountFlag) {
      sidebar = sidebar.filter(c => c.header !== 'Communications');
    }
    sidebar = sidebar.map(list => {
      return get(list, 'pages', []).length > 0 ? (
        <div key={list.header}>
          <MenuItem button onClick={() => handleHeaderClick(list.header)}>
            {state.expandedHeaders.indexOf(list.header) !== -1 ? (
              <ExpandLess />
            ) : (
              <ExpandMore style={{ fontSize: '16px' }} />
            )}
            <ListItemText
              style={{ padding: 0 }}
              disableTypography
              inset
              primary={
                <Typography type="body2" style={styles.formHeaderStyle}>
                  {list.header}
                </Typography>
              }
            />
          </MenuItem>
          <Collapse
            in={state.expandedHeaders.indexOf(list.header) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {sidebarRows(list.pages)}
          </Collapse>
        </div>
      ) : (
        <></>
      );
    });
    return sidebar;
  };

  return (
    <div className={classes.root}>
      <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
        <MenuList component="nav" style={{ paddingTop: '1.5rem', paddingLeft: '35px' }}>
          {generateSidebar(state.sidebarList, state.expandedHeaders)}
        </MenuList>
      </Drawer>
      <main className={`${classes.content} page-content-scroll setup-content-container`}>
        <Notifications />
        <Modal
          className={classes.copyOfficeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isCopyOfficeSettingModalOpen}
          onClose={() => {
            dispatch(loginSetupActions.onToggleCopyOfficeModal());
          }}
          disableBackdropClick={true}
        >
          <CopyOfficeModal season={payload.season} />
        </Modal>
        {state.openComponent}
      </main>
    </div>
  );
};

export default withStyles(compStyles)(AccountSetup);
